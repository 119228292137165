import * as React from "react";
import ShopProductList from "../ShopProductList";
import { Link } from "gatsby";
import "./index.scss";

interface ProductsType {
  settings?: any;
  keyword?: string;
  products: any;
  productsSettings?: any;
  compareList?: any;
  setCompareList?: any;
  openModal?: any;
  setOpenModal?: any;
  light?: any;
  showButton?: any;
}

const ShopList: React.FC<ProductsType> = ({
  settings,
  keyword,
  products,
  productsSettings,
  compareList,
  setCompareList,
  openModal,
  setOpenModal,
  light,
  showButton = true,
}) => {
  return (
    <section
      className={light ? "products-features" : "products-features light"}
    >
      <div className="container">
        <h3 className="prodcut-list-keyword">
          {settings?.subTitle || settings?.seoKeyword || keyword}
        </h3>
        <h2>{settings?.title}</h2>
        <p className="products-features-desc">{settings?.content}</p>
        <div className="product-list">
          {products
            ?.filter((e: any) => {
              return settings?.listProducts
                ?.map((e: any) => e.value)
                .includes(e?.slug);
            })
            .map((e: any, i: any) => {
              return (
                <ShopProductList
                  key={i}
                  product={e}
                  productsSettings={productsSettings}
                  compareList={compareList}
                  setCompareList={setCompareList}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              );
            })}
        </div>
        {showButton && (
          <div className="product-wrap-button">
            <Link to={settings.buttonLink}>
              <button type="button" className="orangeButton">
                {settings.buttonTitle}
              </button>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default ShopList;
