import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./index.scss";

interface InsightsPropTypes {
  settings?: any;
  articles?: any;
  light?: any;
  full?: boolean;
}

const Insights: React.FC<InsightsPropTypes> = ({
  settings,
  articles = [],
  light,
  full = false,
}) => {
  return (
    <section className={light ? "insights" : "insights light"}>
      <div className="container">
        <h3 className="meta">{settings.subTitle}</h3>
        <h2>{settings.title}</h2>
        <p className="insights-desc">{settings.content}</p>
        <div
          className={
            full ? "insights-wrap full-page" : "insights-wrap home-page"
          }
        >
          {articles?.map((node: any, i: any) => {
            const image = getImage(node?.images[0]?.postURL);
            const dateData = new Date(node?.date);
            return (
              <div className="insights-item" key={i}>
                {image && (
                  <Link to={`/${node?.slug}`}>
                    <div className="insights-meta-img">
                      <GatsbyImage image={image} alt="" />
                      <div className="insights-meta-date">
                        <div className="insights-meta-day">
                          {dateData.getDate()}
                        </div>
                        <div className="insights-meta-month">
                          {dateData.toLocaleDateString("en-US", {
                            month: "short",
                          })}
                        </div>
                        <div className="insights-meta-year">
                          {dateData.getFullYear()}
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
                <div className="insights-meta">
                  <div className="insights-date">
                    <AccessTimeIcon />
                    <span>{node?.title.length > 52 ? 6 : 7} min read</span>
                  </div>
                  <div className="insights-title">
                    <Link to={`/${node?.slug}`}>{node?.title}</Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {!full && (
          <div className="insights-button">
            <Link to={settings.buttonLink}>
              <button type="button" className="orangeButton">
                {settings.buttonContent}
              </button>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default Insights;
